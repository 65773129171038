'use client';

import * as Sentry from '@sentry/nextjs';
import type Error from 'next/error';
import { useEffect } from 'react';

const LENNY_GALLERY = 'https://fast.wistia.net/embed/channel/24076kc8tf';

const GlobalError = ({ error }: { error: Error }) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="en">
      <body>
        <div className="Error">
          <div className="Error__message">
            <p className="Error__title">
              Oops! There seems to be a problem with that page.
            </p>
            <a href={LENNY_GALLERY}>See More Lenny →</a>
          </div>
          <div className="Error__image">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              alt="Lenny the dog eating a video tape"
              src="/reno_static/images/lenny-chewing-tape.jpg"
            />
          </div>
        </div>
        <h2>Something went wrong!</h2>
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a href="/">Try going home</a>
      </body>
    </html>
  );
};

export default GlobalError;
